<template>
  <div class="main-content">
    <h3 class="marginBottom20">详情</h3>
    <div>
      <el-button class="marginBottom30" @click="goBack()">返回</el-button>
    </div>
    <div>
      <h3>优惠券信息</h3>
      <el-descriptions :column="3" :size="size" border>
        <el-descriptions-item label="优惠劵编号">{{
          form.code
        }}</el-descriptions-item>
        <el-descriptions-item label="优惠劵名称">{{
          form.name
        }}</el-descriptions-item>
        <el-descriptions-item label="优惠券类型">{{
          form.type == 1 ? "满减" : form.type == 2 ? "代金" : form.type
        }}</el-descriptions-item>

        <el-descriptions-item label="使用条件"
          >{{
            form.type == 1
              ? form.useConditionFullPrice == 0
                ? "无条件"
                : `满${form.useConditionFullPrice}元减${form.useConditionSubtractPrice}`
              : `${form.takePrice}元`
          }}
        </el-descriptions-item>
        <el-descriptions-item label="过期类型">{{
          form.overdueType == 1 ? "使用有效期" : "领取失效"
        }}</el-descriptions-item>
        <el-descriptions-item label="优惠券有效期">
          {{
            form.overdueType == 1
              ? `${form.validityStartTime}  ~ ${form.validityEndTime}`
              : `领取后${form.comeNum}天生效，生效后${form.validityNum}天失效`
          }}
        </el-descriptions-item>
        <el-descriptions-item label="获取方式">{{
          form.getWay == 1
            ? "领用"
            : form.getWay == 2
            ? "购买"
            : form.getWay == 3
            ? "其他平台"
            : form.getWay == 4
            ? "销售合伙人赠送"
            : ""
        }}</el-descriptions-item>
        <el-descriptions-item label="领取时间"
          >{{ form.receiveStartTime }} ~
          {{ form.receiveEndTime }}</el-descriptions-item
        >
        <el-descriptions-item label="每人限领"
          >{{ form.personLimitNum }}张</el-descriptions-item
        >

        <el-descriptions-item label="库存剩余(张)"
          >{{ form.stockNum }}张</el-descriptions-item
        >
        <el-descriptions-item label="已领取"
          >{{ form.drawNum }}张</el-descriptions-item
        >
        <el-descriptions-item label="优惠券使用时间"
          >{{ form.useStartTime }}~{{ form.useEndTime  }}</el-descriptions-item
        >
      </el-descriptions>

      <h3 class="margin20">可参与区域</h3>
      
      <div v-if="form.useScope === 2">
        <el-table :data="form.regions" border style="width: 60%">
          <el-table-column type="index" label="序号" width="100">
          </el-table-column>
          <el-table-column prop="code" label="区域编号"> </el-table-column>
          <el-table-column prop="name" label="区域名称"> </el-table-column>
        </el-table>
      </div>
      <div v-else>全区域</div>

      <h3 class="margin20">使用规则</h3>
      <!-- <div>
        <el-input
        type="textarea"
        autosize 
        placeholder="请输入内容"
        disabled="true"
        v-model="form.useRule">
      </el-input>
      </div> -->
      <div>
        <avue-ueditor
          v-model="form.useRule"
          :options="options"
          :disabled="true"
        ></avue-ueditor>
      </div>
      <!-- <div style="border: 1px solid #EBEEF5;width: 50%;padding: 20px 10px;">
        {{ form.useRule }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { view } from "@/api/coupon/manage";

export default {
  name: "create",

  data() {
    return {
      type: this.$route.query.type,
      loading: false,
      form: {
        state: 1,
        regionIds: [],
      },
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        props: {
          url: "path",
          res: "data",
          name: "path",
        },
      },
      show: false,
      disabled: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getView();
      this.disabled = true;
    }
  },
  methods: {
    getView() {
      this.loading = true;
      view({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;

          this.form = {
            ...res.data,
            validityTime: [
              res.data.validityStartTime,
              res.data.validityEndTime,
            ],
            receiveTime: [res.data.receiveStartTime, res.data.receiveEndTime],
            regionIds: res.data.regions,
          };
          // this.selectData = {
          //   id: res.data.linkType,
          //   name: res.data.typeName,
          // };
        } else {
          this.$router.go(-1);
          this.$message.error(res.msg);
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>